import { Buffer } from 'buffer';
import 'globalthis/auto';
import 'hammerjs';
import 'zone.js'; // Included with Angular CLI.

/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/array';
// import 'core-js/es6/date';
// import 'core-js/es6/function';
// import 'core-js/es6/map';
// import 'core-js/es6/math';
// import 'core-js/es6/number';
// import 'core-js/es6/object';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/regexp';
// import 'core-js/es6/set';
// import 'core-js/es6/string';
// import 'core-js/es6/symbol';
// import 'core-js/es6/weak-map';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js'; // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect';

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js'; // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any).global = window;
(window as any).process = {};
(window as any).process = window;
(window as any).process.browser = true;
(window as any).process.version = '';
(window as any).process.versions = { node: false };
(window as any).global.Buffer = Buffer;

/**
 * This fixes forms in chrome/chromium 80
 */
(function () {
  function getChromeVersion() {
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : false;
  }

  const chromeVersion = getChromeVersion();
  if (chromeVersion && chromeVersion >= 80) {
    Array.prototype.reduce = function (callback /*, initialValue*/) {
      'use strict';
      if (this == null) {
        throw new TypeError(
          'Array.prototype.reduce called on null or undefined',
        );
      }
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function');
      }
      const t = Object(this);
      // tslint:disable-next-line: no-bitwise
      const len = t.length >>> 0;
      let k = 0;
      let value;
      if (arguments.length === 2) {
        value = arguments[1];
      } else {
        while (k < len && !(k in t)) {
          k++;
        }
        if (k >= len) {
          throw new TypeError('Reduce of empty array with no initial value');
        }
        value = t[k++];
      }
      for (; k < len; k++) {
        if (k in t) {
          value = callback(value, t[k], k, t);
        }
      }
      return value;
    };
  }
})();

// @ts-ignore
Date = class extends Date {
  constructor(...options) {
    /**
     * @description Safari doesn't support YYYY-MM-DD in DateConstructor, for this reason
     * we convert the format to YYYY/MM/DD wich is supported for all browsers
     */
    if (
      options?.length &&
      typeof options[0] === 'string' &&
      /**
       * @description Excluding check applied when the first string param is ISOString
       * @example 2021-01-21T23:00:00.000Z
       */
      !options[0].match(
        new RegExp(
          '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$',
          'g',
        ),
      ) &&
      /**
       * @description Excluding check applied when the first string param is numeric like a timestamp
       * @example 1611270000000 (Timestamp of )
       */
      !options[0].match(new RegExp('^[0-9]+.[0-9]+$'))
    ) {
      super(options[0].replace(/-/g, '/'));
    } else {
      // @ts-ignore
      super(...options);
    }

    /**
     * @description If the first parameter of constructor is a string and have the format
     * YYYY-MM-DD automatically hours 0 is applied
     */
    if (
      typeof options?.[0] === 'string' &&
      options[0].match(
        new RegExp(
          '^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$',
          'g',
        ),
      )
    ) {
      this.setHours(0, 0, 0, 0);
    }
  }
};
